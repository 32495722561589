import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  listProducts,
  listProductsAllTopicss,
  listProductsCategories,
  listProductsCountries,
  listProductsMoods,
  listProductsSubTopics,
} from "../actions/productActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { moodLogos } from "../components/MoodLogos";
import Product from "../components/Product";
import Rating from "../components/Rating";
import { ratings } from "../utils";
import Helmet from "../../node_modules/react-helmet/lib/Helmet";
import axios from "../../node_modules/axios/index";

export default function TopScreen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get the URL segment directly during initial state setup
  const currentUrl = window.location.href.split("top/")[1];

  // Find the corresponding top using the URL segment right away
  const [selectedTop, setSelectedTop] = useState("");

  useEffect(() => {
    async function getChannels() {
      let responseTop = await axios.get(
        process.env.REACT_APP_API_ADDRESS + `toppages/topurl/${currentUrl}`
      );
      setSelectedTop(responseTop.data[0]);
    }

    getChannels();
  }, []);

  // Set the state with the initially computed values

  const [moreFilters, setMoreFilters] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProductsCategories());
    dispatch(listProductsCountries());

    dispatch(listProductsAllTopicss());
    dispatch(listProductsMoods());
  }, [dispatch]);

  const selectedCountry = selectedTop?.country || "all";
  const selectedOrder = selectedTop?.order || "highest";

  const {
    /* pagination = 0,  */
    name = "all",
    category = selectedTop?.category || "all",
    country = selectedCountry,
    min = 0,
    max = 0,
    rating = selectedTop?.minimumRating || 0,
    ratingFun = 0,
    ratingLearn = 0,
    ratingEmotional = 0,
    numPage = 0,
    order = selectedOrder,
    rankingRatingMoodDef = selectedTop?.mood || "all",
    language = "all",
    duration = "all",
    subscriber = "all",
    context = "all",
    level = "all",
    tag = selectedTop?.tag || "all",
    allTopics = selectedTop?.mainTopic || "all",
    subTopic = selectedTop?.subTopic || "all",
    platform = "all",
  } = useParams();

  useEffect(() => {
    dispatch(listProductsSubTopics(allTopics));
  }, [allTopics]);

  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const productCategoryList = useSelector((state) => state.productCategoryList);
  const {
    loading: loadingCategories,
    error: errorCategories,
    categories,
  } = productCategoryList;

  const productCountryList = useSelector((state) => state.productCountryList);
  const {
    loading: loadingCountries,
    error: errorCountries,
    countries,
  } = productCountryList;

  const productMoodList = useSelector((state) => state.productMoodList);
  const {
    loading: loadingMoods,
    error: errorMoods,
    rankingRatingMoodDefs,
  } = productMoodList;

  const productSubTopicList = useSelector((state) => state.productSubTopicList);
  const {
    loading: loadingSubTopics,
    error: errorSubTopics,
    subtopics,
  } = productSubTopicList;

  const productAllTopicsList = useSelector(
    (state) => state.productAllTopicsList
  );
  const {
    loading: loadingAllTopicss,
    error: errorAllTopicss,
    alltopicss,
  } = productAllTopicsList;

  let categoriesFiltered = [];
  categoriesFiltered = products?.map((obj) => {
    return obj.category;
  });

  let subTopicsFiltered = [];
  products?.map((obj) => {
    obj.allTopics.map((x) => subTopicsFiltered.push(x.subTopic));
  });

  useEffect(() => {
    setCurrentPage(0);

    if (selectedTop) {
      dispatch(
        listProducts({
          /* pagination,  */
          name: name !== "all" ? name : "",
          category: category !== "all" ? category : "",
          country: country !== "all" ? country : "",

          min,
          max,
          rating,
          ratingFun,
          ratingLearn,
          ratingEmotional,
          numPage,
          order,
          rankingRatingMoodDef:
            rankingRatingMoodDef !== "all" ? rankingRatingMoodDef : "",
          language: language !== "all" ? language : "English",
          duration: duration !== "all" ? duration : "",
          subscriber: subscriber !== "all" ? subscriber : "",
          context: context !== "all" ? context : "",
          level: level !== "all" ? level : "",
          tag: tag !== "all" ? tag : "",
          platform: platform !== "all" ? platform : "",
          subTopic: subTopic !== "all" ? subTopic : "",
          allTopics: allTopics !== "all" ? allTopics : "",
        })
      );
    } // name equal to, if name doesn't equal to all then use name then use empty string
  }, [
    category,
    country,
    dispatch,
    name,
    /* pagination,   */
    max,
    min,
    rating,
    ratingFun,
    ratingLearn,
    ratingEmotional,
    numPage,
    order,
    rankingRatingMoodDef,
    language,
    duration,
    subscriber,
    context,
    level,
    tag,
    platform,
    allTopics,
    subTopic,
  ]);

  const getFilterUrl = (filter) => {
    const filterCategory = filter.category || category;
    const filterCountry = filter.country || country;

    /* const filterPagination = filter.pagination || pagination;  */

    const filterName = filter.name || name;
    const filterRating = filter.rating || rating;
    const filterRatingFun = filter.ratingFun || ratingFun;
    const filterRatingLearn = filter.ratingLearn || ratingLearn;
    const filterRatingEmotional = filter.ratingEmotional || ratingEmotional;
    const sortOrder = filter.order || order;
    const filterMin = filter.min ? filter.min : filter.min === 0 ? 0 : min;
    const filterMax = filter.max ? filter.max : filter.max === 0 ? 0 : max;
    const filterRankingRatingMoodDef =
      filter.rankingRatingMoodDef || rankingRatingMoodDef;
    const filterLanguage = filter.language || language;
    const filterDuration = filter.duration || duration;
    const filterSubscriber = filter.subscriber || subscriber;

    const filterContext = filter.context || context;
    const filterLevel = filter.level || level;
    const filterTag =
      filter?.tag != "all" &&
      filter.allTopics == "all" &&
      filter.category == "all"
        ? filter.tag || tag
        : "all";
    const filterPlatform = filter.platform || platform;
    const filterAllTopics = filter.allTopics || allTopics;
    const filterSubTopic = filter.subTopic || subTopic;

    return `/search?category=${filterCategory}&country=${filterCountry}&name=${filterName}&min=${filterMin}&max=${filterMax}&rating=${filterRating}&order=${sortOrder}&language=${filterLanguage}&allTopics=${filterAllTopics}&subTopic=${filterSubTopic}&rankingRatingMoodDef=${filterRankingRatingMoodDef}&platform=${filterPlatform}&tag=${filterTag}&duration=${filterDuration}&subscriber=${filterSubscriber}`;
  };

  const [categoryFilter, setCategoryFilter] = useState(false);
  const [countryFilter, setCountryFilter] = useState(false);

  const [moodFilter, setMoodFilter] = useState(false);

  const [durationFilter, setDurationFilter] = useState(false);
  const [subscriberFilter, setSubscriberFilter] = useState(false);

  const [allTopicsFilter, setAllTopicsFilter] = useState(false);

  const [nbReviewsFilter, setNbReviewsFilter] = useState(false);

  const nbPages = Math.ceil(products?.length / 24) || 0;

  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(currentPage * 0 + data.selected * 24);
    window.scrollTo(0, 0);
  };

  const isMobile = !useMediaQuery({
    query: "(min-width: 980px)",
  });

  const isMobileFilter = !useMediaQuery({
    query: "(min-width: 600px)",
  });

  const items = [
    {
      href: "/top/best-rated-youtube-channels",
      text: "💖 Best rated channels",
    },
    {
      href: "/top/best-rated-sport-youtube-channels",
      text: "🏉 Best sport channels",
    },
    {
      href: "/top/best-rated-gaming-youtube-channels",
      text: "👾 Best rated gaming channels",
    },
    {
      href: "/top/best-rated-minecraft-youtube-channels",
      text: "⚒️ Best rated Minecraft channels",
    },
    {
      href: "/top/best-rated-comedy-youtube-channels",
      text: "😝 Best rated comedy channels",
    },
    {
      href: "/top/best-rated-engineering-youtube-channels",
      text: "🧑🏻‍🔬 Best rated engineering channels",
    },
    {
      href: "/top/most-popular-vlog-youtube-channels",
      text: "🏖️ Most popular vloggers",
    },
    {
      href: "/top/most-relaxing-youtube-channels",
      text: "🧘‍♀️ Most relaxing Channels",
    },
    {
      href: "/top/best-rated-youtube-channels-about-japan",
      text: "🇯🇵 Best rated channels about Japan",
    },
    {
      href: "/top/best-rated-science-youtubers",
      text: "🔬 Best-rated science channels",
    },
    {
      href: "/top/best-rated-us-youtube-channels",
      text: "🇺🇸 Best rated US YouTube channels",
    },
    {
      href: "/top/best-rated-uk-youtube-channels",
      text: "🇬🇧 Best rated UK YouTube channels",
    },

    {
      href: "/top/worst-rated-us-youtube-channels",
      text: "🇺🇸 Worst rated US YouTube channels",
    },
    {
      href: "/top/best-crafts-youtube-channels",
      text: "✂️ Best Crafts Channels On YouTube",
    },
    {
      href: "/top/best-survival-youtube-channels",
      text: "🏕️ Best Survival YouTube channels",
    },
    {
      href: "/top/best-retrogaming-youtube-channels",
      text: "🕹️ Retrogaming YouTube channels",
    },
    {
      href: "/top/best-rated-nintendo-youtube-channels",
      text: "🕹️ Nintendo YouTube channels",
    },
    {
      href: "/top/photography-youtube-channels",
      text: "📷 Best Rated Photography Channels",
    },
    {
      href: "/top/best-tennis-youtube-channels",
      text: "🎾 Channels About Tennis",
    },
    {
      href: "/top/best-gardening-youtube-channels",
      text: "🏡 Best Gardening Channels",
    },
    {
      href: "/top/best-drama-youtube-channels",
      text: "☕ Best Drama Channels",
    },
    {
      href: "/top/car-youtube-channels",
      text: "🚗 Best Car Youtubers",
    },
    {
      href: "/top/best-couple-youtube-channels",
      text: " 👩‍❤️‍👨 Best Couple Channels",
    },
    {
      href: "/top/basketball-youtube-channels",
      text: "🏀 Best Basketball Channels",
    },
    {
      href: "/top/history-youtube-channels",
      text: "📜 Top History Channels",
    },
    {
      href: "/top/true-crime-youtube-channels",
      text: "🩸 Best True Crime Channels",
    },
    {
      href: "/top/economy-youtube-channels",
      text: "💵 Top 100 Economy Channels",
    },
    {
      href: "/top/best-movie-review-youtube-channels",
      text: "🍿 Movie Review Channels",
    },
    {
      href: "/top/football-youtube-channels",
      text: "⚽ Top Football Channels",
    },
    {
      href: "/top/worst-rated-youtube-channels",
      text: "👎 Worst YouTube Channels",
    },
    {
      href: "/top/roblox-youtube-channels",
      text: "🧱 Best Roblox YouTubers",
    },

    {
      href: "/top/gta-youtube-channels",
      text: "🚔 Best GTA YouTubers",
    },

    {
      href: "/top/pokemon-youtube-channels",
      text: "🐛 Best Pokémon YouTubers",
    },

    {
      href: "/top/fortnite-youtube-channels",
      text: "🔫 Top Fortnite YouTube Channels",
    },

    {
      href: "/top/tech-youtube-channels",
      text: "📱 Best Rated Tech YouTubers",
    },

    {
      href: "/top/cooking-youtube-channels",
      text: "👩‍🍳 Best Reviewed Cooking Channels",
    },

    {
      href: "/top/family-youtube-channels",
      text: "👨‍👩‍👧‍👦 Top Family Channels",
    },
    {
      href: "/top/makeup-youtube-channels",
      text: "💄 Top Makeup YouTubers",
    },
    {
      href: "/top/crypto-youtube-channels",
      text: "🪙 Best Rated Crypto Channels",
    },
    {
      href: "/top/lego-youtube-channels",
      text: "🧱 Best Rated Lego YouTubers",
    },
    {
      href: "/top/asmr-youtube-channels",
      text: "💆 Top ASMR YouTube Channels",
    },
    {
      href: "/top/fashion-youtube-channels",
      text: "👜 Best Fashion Channels",
    },
    {
      href: "/top/diy-youtube-channels",
      text: "✂️ Best DIY YouTube Channels",
    },
    {
      href: "/top/best-kids-youtube-channels",
      text: "🧒 Best Kids YouTube Channels",
    },
    {
      href: "/top/chess-youtube-channels",
      text: "♟️ Top Chess YouTube Channels",
    },
    {
      href: "/top/poker-youtube-channels",
      text: "♦️ Top Poker YouTubers",
    },
    {
      href: "/top/most-subscribed-canada-youtube-channels",
      text: "🇨🇦 Most Subscribed Canadian Channels",
    },
    {
      href: "/top/most-subscribed-austrlia-youtube-channels",
      text: "🇦🇺 Most Subscribed Australian Channels",
    },
  ];

  function getRandomItems(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  }

  const [randomItems, setRandomItems] = useState([]);

  useEffect(() => {
    // Run this only once on mount
    const randomItems = getRandomItems(items, 12);
    setRandomItems(randomItems);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {selectedTop?.title
            ? selectedTop.title
            : "Find your favorite channel | Favoree"}
        </title>
        <meta
          name="description"
          content={
            selectedTop
              ? selectedTop.description
              : "Searching for quality content? Here’s a complete list of the best YouTube channels. Sort by popularity, topic, rating, duration and more"
          }
        ></meta>

        <link
          rel="canonical"
          href={
            selectedTop
              ? "https://www.favoree.io/top/" + selectedTop.url
              : "https://www.favoree.io/search"
          }
        ></link>
        <link
          rel="keywords"
          content="Favoree, YouTube, Channel, Discover, Find, List, Filter, Creator, YouTuber, Content, Rate, Review"
        ></link>
        <meta
          property="og:image"
          content={
            selectedTop && selectedTop.image
              ? selectedTop.image
              : "/images/cardGroup.png"
          }
        />

        <meta
          name="twitter:image"
          content={
            selectedTop && selectedTop.image
              ? selectedTop.image
              : "/images/cardGroup.png"
          }
        />
      </Helmet>
      <div className="cover" id="scdColor">
        <div className="coverInside">
          <div className="twoThird">
            <h1>{selectedTop && selectedTop.title}</h1>
            <p>{selectedTop && selectedTop.description}</p>
          </div>
          {!isMobile && (
            <div className="oneThird">
              <img
                className="headerPicture"
                src={
                  selectedTop && selectedTop.image
                    ? selectedTop.image
                    : "/images/cardGroup.png"
                }
                alt="YouTube channel with ratings"
              ></img>
            </div>
          )}
        </div>
      </div>
      <div className="space"></div>
      {!isMobile && (
        <div>
          <div className="space"></div>
          <div className="space"></div>{" "}
        </div>
      )}
      <div className="mainElement">
        <div className="mainInside">
          <div className="row">
            <h3>
              <i className="fa fa-filter" aria-hidden="true"></i>{" "}
              Filters&nbsp;&nbsp;
            </h3>
            <div>
              Sort by &nbsp;
              <select
                value={order}
                onChange={(e) => {
                  props.history.push(getFilterUrl({ order: e.target.value }));
                }}
              >
                <option value="maxSub">Most YouTube subscribers</option>
                <option value="minSub">Least YouTube subscribers</option>
                <option value="toprated">Best Favoree rating</option>
                <option value="lowrated">Worst Favoree rating</option>
                <option value="highest">Most Reviewed</option>
                <option value="latest">Latest on Favoree</option>
              </select>
            </div>
          </div>
          {isMobile && <div className="space"></div>}
          <div className="row top">
            <div className="col-1">
              {(category !== "all") |
              (country !== "all") |
              (level !== "all") |
              (allTopics !== "all") |
              (rankingRatingMoodDef !== "all") |
              (rating != 0) |
              (duration !== "all") |
              (subscriber !== "all") |
              (min != 0) |
              (max != 0) ? (
                <div>
                  <div className="space"></div>
                  <a>
                    <Link to="/search">
                      &emsp; <i className="fa-solid fa-xmark"></i> &nbsp; Clear
                      all filters
                    </Link>
                  </a>{" "}
                  <div className="space"></div>
                </div>
              ) : (
                <div></div>
              )}

              <div className="dropdownFilter">
                <button
                  id="btnFilter"
                  onClick={() => setAllTopicsFilter(!allTopicsFilter)}
                >
                  {(allTopics == "all") & (subTopic == "all") ? (
                    <span></span>
                  ) : (
                    <span className="round Checked"></span>
                  )}
                  <i className="fa-solid fa-icons"></i>
                  <text>Topics </text>
                  <i
                    id={`${allTopicsFilter ? "arrowTurn" : ""}`}
                    className="fa fa-caret-down"
                  ></i>{" "}
                </button>
                <div className={`filterBox${allTopicsFilter ? "Active" : ""}`}>
                  {loadingAllTopicss ? (
                    <LoadingBox></LoadingBox>
                  ) : errorAllTopicss ? (
                    <MessageBox variant="danger">{error}</MessageBox>
                  ) : (
                    <ul className="subFilter">
                      <text>Main topic</text>
                      <li>
                        <Link
                          className={
                            "all" === allTopics ? "active" : "notactive"
                          }
                          to={getFilterUrl({
                            allTopics: "all",
                            subTopic: "all",
                          })}
                        >
                          <input
                            type="checkbox"
                            checked={"all" === allTopics ? "checked" : ""}
                          ></input>
                          All
                        </Link>
                      </li>
                      {alltopicss.map((c) => (
                        <li key={c}>
                          <Link
                            className={
                              allTopics.includes(c) ? "active" : "notactive"
                            }
                            to={getFilterUrl({
                              allTopics: allTopics.includes(c)
                                ? allTopics.replace("_" + c, "")
                                : allTopics + "_" + c,
                              subTopic: "all",
                            })}
                          >
                            <input
                              type="checkbox"
                              checked={allTopics.includes(c) ? "checked" : ""}
                            ></input>
                            {c.replace(" and", " &")}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                  {loadingSubTopics ? (
                    <LoadingBox></LoadingBox>
                  ) : errorSubTopics ? (
                    <MessageBox variant="danger">{error}</MessageBox>
                  ) : "all" === allTopics && subTopic === "all" ? (
                    <div>
                      {" "}
                      <ul>
                        <text>Sub topic</text>
                        <strong></strong>
                        <p className="grey">Select a main topic... </p>
                      </ul>{" "}
                    </div>
                  ) : (
                    <ul>
                      <text>Sub topic</text>
                      <li>
                        <Link
                          className={
                            "all" === subTopic ? "active" : "notactive"
                          }
                          to={getFilterUrl({ subTopic: "all" })}
                        >
                          <input
                            type="checkbox"
                            checked={"all" === subTopic ? "checked" : ""}
                          ></input>
                          All
                        </Link>
                      </li>
                      {(subtopics.length > 0
                        ? subtopics.sort((a, b) => b.count - a.count)
                        : [subTopic]
                      ).map((c) => (
                        <li key={c._id}>
                          <Link
                            className={
                              subTopic.includes(c._id) ? "active" : "notactive"
                            }
                            to={getFilterUrl({
                              subTopic: subTopic.includes(c._id)
                                ? subTopic.replace("_" + c._id, "")
                                : subTopic + "_" + c._id,
                            })}
                          >
                            <input
                              type="checkbox"
                              checked={
                                subTopic.includes(c._id) ? "checked" : ""
                              }
                            ></input>
                            {c._id && c._id.replace(" and", " &")}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              <div className="dropdownFilter">
                <button
                  id="btnFilter"
                  onClick={() => setDurationFilter(!durationFilter)}
                >
                  {"all" === duration ? (
                    <span></span>
                  ) : (
                    <span className="round Checked"></span>
                  )}
                  <i className="fa-solid fa-hourglass-end"></i>{" "}
                  <text> Average duration </text>
                  <i
                    id={`${durationFilter ? "arrowTurn" : ""}`}
                    className="fa fa-caret-down"
                  ></i>{" "}
                </button>
                <div className={`filterBox${durationFilter ? "Active" : ""}`}>
                  <Link
                    className={
                      duration == 0 || duration == "all"
                        ? "active"
                        : "notactive"
                    }
                    to={getFilterUrl({ duration: "all" })}
                  >
                    <input
                      type="checkbox"
                      checked={
                        duration == 0 || duration == "all" ? "checked" : ""
                      }
                    ></input>
                    All
                  </Link>
                  {["0-5", "6-15", "16-30", "31-60", "61-600"].map((c) => (
                    <li key={c}>
                      <Link
                        className={
                          duration.includes(c) ? "active" : "notactive"
                        }
                        to={getFilterUrl({
                          duration:
                            duration == c
                              ? duration.replace(c, "all")
                              : duration == "all"
                              ? duration.replace("all", c)
                              : duration.includes(c)
                              ? duration.replace(c, "").replace("--", "-") ==
                                "-"
                                ? "all"
                                : duration.replace(c, "").replace("--", "-")
                              : /* if there is only "-" remaining, replace it by "all" */
                                duration + "-" + c,
                        })}
                      >
                        <input
                          type="checkbox"
                          checked={duration.includes(c) ? "checked" : ""}
                        ></input>
                        {c.replace("-600", " and more")} min
                      </Link>
                    </li>
                  ))}
                </div>
              </div>
              <div className="dropdownFilter">
                <button
                  id="btnFilter"
                  onClick={() => setSubscriberFilter(!subscriberFilter)}
                >
                  {"all" === subscriber ? (
                    <span></span>
                  ) : (
                    <span className="round Checked"></span>
                  )}
                  <i class="fa-solid fa-users"></i>{" "}
                  <text>Subscriber count</text>
                  <i
                    id={`${subscriberFilter ? "arrowTurn" : ""}`}
                    className="fa fa-caret-down"
                  ></i>{" "}
                </button>
                <div className={`filterBox${subscriberFilter ? "Active" : ""}`}>
                  <Link
                    className={
                      subscriber == 0 || subscriber == "all"
                        ? "active"
                        : "notactive"
                    }
                    to={getFilterUrl({ subscriber: "all" })}
                  >
                    <input
                      type="checkbox"
                      checked={
                        subscriber == 0 || subscriber == "all" ? "checked" : ""
                      }
                    ></input>
                    All
                  </Link>
                  {[
                    "1-1000",
                    "1000-10000",
                    "10000-100000",
                    "100000-1000000",
                    "1000000-10000000",
                    "10000000-1000000000",
                  ].map((c) => (
                    <li key={c}>
                      <Link
                        className={
                          subscriber.includes(c) ? "active" : "notactive"
                        }
                        to={getFilterUrl({
                          subscriber:
                            subscriber == c
                              ? subscriber.replace(c, "all")
                              : subscriber == "all"
                              ? subscriber.replace("all", c)
                              : subscriber.includes(c)
                              ? subscriber.replace(c, "").replace(",,", ",") ==
                                ","
                                ? "all"
                                : subscriber.replace(c, "").replace(",,", ",")
                              : subscriber + "," + c,
                        })}
                      >
                        <input
                          type="checkbox"
                          checked={subscriber.includes(c) ? "checked" : ""}
                        ></input>
                        {c
                          .replace("000000000", "B")
                          .replace("0000000", "0M")

                          .replace("000000", "M")
                          .replace("00000", "00K")
                          .replace("0000", "0K")

                          .replace("000", "K")}
                      </Link>
                    </li>
                  ))}
                </div>
              </div>
              {!isMobileFilter | moreFilters ? (
                <div>
                  <div className="dropdownFilter">
                    <button
                      id="btnFilter"
                      onClick={() => setMoodFilter(!moodFilter)}
                    >
                      {rankingRatingMoodDef == "all" ? (
                        <span></span>
                      ) : (
                        <span className="round Checked"></span>
                      )}
                      <i className="fa-solid fa-masks-theater"></i>
                      <text> Mood </text>
                      <i
                        id={`${moodFilter ? "arrowTurn" : ""}`}
                        className="fa fa-caret-down"
                      ></i>{" "}
                    </button>
                    <div className={`filterBox${moodFilter ? "Active" : ""}`}>
                      {loadingMoods ? (
                        <LoadingBox></LoadingBox>
                      ) : errorMoods ? (
                        <MessageBox variant="danger">{error}</MessageBox>
                      ) : (
                        <ul className="filterBubbles">
                          <li>
                            <Link
                              className={
                                "all" === rankingRatingMoodDef
                                  ? "active"
                                  : "notactive"
                              }
                              to={getFilterUrl({ rankingRatingMoodDef: "all" })}
                            >
                              <a className="topicFilter">
                                <input
                                  type="checkbox"
                                  checked={
                                    "all" === rankingRatingMoodDef
                                      ? "checked"
                                      : ""
                                  }
                                ></input>
                                All
                              </a>
                            </Link>
                          </li>
                          {rankingRatingMoodDefs
                            .filter((x) => x != "" && x != " " && x != "Fun")
                            .map((c) => (
                              <li key={c}>
                                <Link
                                  className={
                                    rankingRatingMoodDef.includes(c)
                                      ? "active"
                                      : "notactive"
                                  }
                                  to={getFilterUrl({
                                    rankingRatingMoodDef:
                                      rankingRatingMoodDef.includes(c)
                                        ? rankingRatingMoodDef.replace(
                                            "_" + c,
                                            ""
                                          )
                                        : rankingRatingMoodDef + "_" + c,
                                  })}
                                >
                                  <a className="topicFilter">
                                    <input
                                      type="checkbox"
                                      checked={
                                        rankingRatingMoodDef.includes(c)
                                          ? "checked"
                                          : ""
                                      }
                                    ></input>
                                    {c}{" "}
                                    <i
                                      className={
                                        moodLogos.find((y) => y.name === c)
                                          ? moodLogos.find((y) => y.name === c)
                                              .emoticonClass
                                          : ""
                                      }
                                    ></i>
                                  </a>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="dropdownFilter">
                    <button
                      id="btnFilter"
                      onClick={() => setNbReviewsFilter(!nbReviewsFilter)}
                    >
                      {(min == 0) & (rating == 0) ? (
                        <span></span>
                      ) : (
                        <span className="round Checked"></span>
                      )}
                      <i className="fa-regular fa-star"></i>{" "}
                      <text>Favoree reviews </text>
                      <i
                        id={`${nbReviewsFilter ? "arrowTurn" : ""}`}
                        className="fa fa-caret-down"
                      ></i>{" "}
                    </button>
                    <div
                      className={`filterBox${nbReviewsFilter ? "Active" : ""}`}
                    >
                      <ul className="subFilter">
                        <text>Average review</text>
                        {ratings.map((r) => (
                          <li key={r.name}>
                            <Link
                              to={getFilterUrl({ rating: r.rating })}
                              className={
                                `${r.rating}` === `${rating}`
                                  ? "active"
                                  : "notactive"
                              }
                            >
                              <Rating
                                caption={" & up"}
                                rating={r.rating}
                              ></Rating>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="dropdownFilter">
                    <button
                      id="btnFilter"
                      onClick={() => setCategoryFilter(!categoryFilter)}
                    >
                      {category == "all" ? (
                        <span></span>
                      ) : (
                        <span className="round Checked"></span>
                      )}
                      <i className="fa-solid fa-tv"></i>{" "}
                      <text> Categories </text>
                      <i
                        id={`${categoryFilter ? "arrowTurn" : ""}`}
                        className="fa fa-caret-down"
                      ></i>{" "}
                    </button>
                    <div
                      className={`filterBox${categoryFilter ? "Active" : ""}`}
                    >
                      {loadingCategories ? (
                        <LoadingBox></LoadingBox>
                      ) : errorCategories ? (
                        <MessageBox variant="danger">{error}</MessageBox>
                      ) : (
                        <ul className="filterBubbles">
                          <li>
                            <Link
                              className={
                                "all" === category ? "active" : "notactive"
                              }
                              to={getFilterUrl({ category: "all" })}
                            >
                              <a className="topicFilter">
                                <input
                                  type="checkbox"
                                  checked={"all" === category ? "checked" : ""}
                                ></input>
                                All
                              </a>
                            </Link>
                          </li>
                          {categories
                            /* .filter((item) =>
                      categoriesFiltered && "all" === category
                        ? categoriesFiltered.includes(item)
                        : item
                    ) */
                            .map((c) => (
                              <li key={c}>
                                <Link
                                  className={
                                    category.includes(c)
                                      ? "active"
                                      : "notactive"
                                  }
                                  to={getFilterUrl({
                                    category: category.includes(c)
                                      ? category.replace("_" + c, "")
                                      : category + "_" + c,
                                  })}
                                >
                                  <a className="topicFilter">
                                    <input
                                      type="checkbox"
                                      checked={
                                        category.includes(c) ? "checked" : ""
                                      }
                                    ></input>
                                    {c.replace("and", "&")}
                                  </a>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  </div>{" "}
                  <div className="dropdownFilter">
                    <button
                      id="btnFilter"
                      onClick={() => setCountryFilter(!countryFilter)}
                    >
                      {country == "all" ? (
                        <span></span>
                      ) : (
                        <span className="round Checked"></span>
                      )}
                      <i class="fa-solid fa-earth-americas"></i>{" "}
                      <text> Countries </text>
                      <i
                        id={`${countryFilter ? "arrowTurn" : ""}`}
                        className="fa fa-caret-down"
                      ></i>{" "}
                    </button>
                    <div
                      className={`filterBox${countryFilter ? "Active" : ""}`}
                    >
                      {loadingCountries ? (
                        <LoadingBox></LoadingBox>
                      ) : errorCountries ? (
                        <MessageBox variant="danger">{error}</MessageBox>
                      ) : (
                        <ul className="filterBubbles">
                          <li>
                            <Link
                              className={
                                "all" === country ? "active" : "notactive"
                              }
                              to={getFilterUrl({ country: "all" })}
                            >
                              <a className="topicFilter">
                                <input
                                  type="checkbox"
                                  checked={"all" === country ? "checked" : ""}
                                ></input>
                                All
                              </a>
                            </Link>
                          </li>
                          {countries.map((c) => (
                            <li key={c}>
                              <Link
                                className={
                                  country.includes(c) ? "active" : "notactive"
                                }
                                to={getFilterUrl({
                                  country: country.includes(c)
                                    ? country.replace("_" + c, "")
                                    : country + "_" + c,
                                })}
                              >
                                <a className="topicFilter">
                                  <input
                                    type="checkbox"
                                    checked={
                                      country.includes(c) ? "checked" : ""
                                    }
                                  ></input>
                                  {c}
                                </a>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>{" "}
                  {!isMobileFilter && (
                    <div className="alignVertical" id="getData">
                      <div className="space"> </div>
                      <div className="space"> </div>
                      <div className="space"> </div>

                      <text>
                        We are building the largest YouTube channel database
                      </text>
                      <div className="space"> </div>

                      <a
                        href="https://blog.favoree.io/data-services-favoree/?ref=searchPage"
                        className="giveReview"
                        target="_blank"
                      >
                        📊 Get data and insights
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                <button
                  className="moreFilters"
                  onClick={() => setMoreFilters(true)}
                >
                  <i class="fa-solid fa-chevron-down"></i> {"  "}More filters
                </button>
              )}
              {isMobileFilter && moreFilters ? (
                <button
                  className="moreFilters"
                  onClick={() => setMoreFilters(false)}
                >
                  <i class="fa-solid fa-chevron-up"></i> {"  "}Less filters
                </button>
              ) : (
                <div></div>
              )}
              <div className="space"></div>
            </div>
            <div className="col-2" id="minHeightFilter">
              {products && (
                <div className="number_programs">
                  {products.length >= 100 ? "100+" : products.length} results{" "}
                </div>
              )}
              {loading ? (
                <LoadingBox></LoadingBox>
              ) : error ? (
                <MessageBox variant="danger">{error}</MessageBox>
              ) : (
                <>
                  {products.length === 0 && (
                    <div>
                      <MessageBox>
                        No results for now{" "}
                        <i className="fa-regular fa-face-frown"></i>. More
                        content coming soon!{" "}
                      </MessageBox>
                      <MessageBox>
                        If you want us to add a channel not on the platform yet,
                        please submit it
                        <a> </a>
                        <a href="/add-channel">here 🔗</a>
                      </MessageBox>
                    </div>
                  )}
                  <div className="row center">
                    {products
                      .slice(currentPage, currentPage + 24)
                      .map((product) => (
                        <Product key={product._id} product={product}></Product>
                      ))}
                  </div>
                </>
              )}
              {products && (
                <div>
                  {products.length !== 0 ? (
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={nbPages}
                      marginPagesDisplayed={3}
                      onPageChange={handlePageClick} /* {handlePageClick}   */
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active-pagination "}
                    ></ReactPaginate>
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="space"></div>

          <div className="space"></div>
          <div style={{ "max-width": "130rem" }}>
            <h2>{selectedTop ? selectedTop.h2 : "Top YouTube channels"}</h2>
            <p>
              {selectedTop
                ? selectedTop.secondDescription
                : "Top YouTube channels"}
            </p>
          </div>

          <div className="space"></div>
        </div>
      </div>

      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>

      <div className="cover" id="thrdColor">
        <div className="coverInside">
          <div className="twoThird">
            <h4>Can't find your favorite channel?</h4>
            <div className="space"></div>

            <a className="buttonLightGrey" id="darkBlue" href="/add-channel">
              {" "}
              Add new channel
            </a>
          </div>
          {!isMobile && (
            <div className="oneThird">
              <img
                className="headerPicture"
                src="/images/addChannel.png"
                alt="add a Channel"
              ></img>
            </div>
          )}
        </div>
      </div>

      <div className="cover" id="darkGrey">
        <div className="space"></div>
        <div className="space"></div>
        <div className="coverInside">
          <h4>Top lists</h4>
          <div className="space"></div>

          <div
            className="title-grid"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
              gap: "10px",
            }}
          >
            {randomItems.map((item, index) => (
              <a key={index} href={item.href} id="leftAlign">
                {item.text}
              </a>
            ))}
          </div>
        </div>{" "}
        <div className="space"></div>
        <div className="space"></div>
        <div className="space"></div>
      </div>
    </div>
  );
}
